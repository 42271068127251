import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import 'hammerjs'; // Needed for Touch functionality of Material Components
import { environment } from '../environments/environment';
import { RoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

import { ToastrModule } from 'ngx-toastr';
import { InterceptorService } from './api/interceptor.service';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { UserState } from './states/user-state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular Core Module // Don't remove!
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Fury Core Modules
    CoreModule,
    RoutingModule,

    // Register a Service Worker (optional)
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),

    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),

    NgxsModule.forRoot([
      UserState
    ]),

    NgxsReduxDevtoolsPluginModule.forRoot()
  ],
  providers: [
    { provide: 'BASE_URL', useValue: 'https://api.askme.fttm.itb.ac.id/api/' },
    // { provide: 'BASE_URL', useValue: 'http://127.0.0.1:3333/api/' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
