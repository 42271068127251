import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { AuthGuard } from './api/auth.guard';
import { GuestGuard } from './api/guest.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', loadChildren: 'app/modules/dashboard/dashboard.module#DashboardModule' },
      { path: 'profile', loadChildren: 'app/modules/profile/profile.module#ProfileModule' },
      { path: 'complaints', loadChildren: 'app/modules/complaints/complaints.module#ComplaintsModule' },
      { path: 'users', loadChildren: 'app/modules/users/users.module#UsersModule' },
      { path: 'inactive-users', loadChildren: 'app/modules/inactive-users/inactive-users.module#InactiveUsersModule' },
      { path: 'links', loadChildren: 'app/modules/links/links.module#LinksModule' },
    ]
  },
  {
    path: 'login',
    canActivate: [GuestGuard],
    loadChildren: 'app/modules/login/login.module#LoginModule',
  },
  {
    path: 'privacy-policy',
    loadChildren: 'app/modules/privacy-policy/privacy-policy.module#PrivacyPolicyModule',
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, GuestGuard]
})
export class RoutingModule {
}
