import { State, StateContext, Action } from "@ngxs/store";
import { User } from "../model/user";

export class LoadUser {
  static readonly type = '[User] Load User'
  constructor(public payload: User) {}
}

export class RemoveUser {
  static readonly type = '[User] Remove User'
  constructor() {}
}

@State<User>({
  name: 'user',
  defaults: null
})
export class UserState {
  @Action(LoadUser)
  load(ctx: StateContext<User>, { payload }: LoadUser) {
    ctx.setState(payload);
  }

  @Action(RemoveUser)
  remove(ctx: StateContext<User>) {
    ctx.setState(null);
  }
}
