import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { AuthService } from './auth.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private authService: AuthService
  ) { }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // check is full url
    const regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (regexp.test(req.url)) {
      return next.handle(req);
    }

    // is not full url
    const clonedreq = req.clone({
      url: `${this.baseUrl}${req.url}`,
      headers: req.headers.set('Authorization', 'Bearer ' + this.authService.getToken())
    });

    return next.handle(clonedreq).do(
      () => {},
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.authService.removeLocalStorage();
          this.router.navigateByUrl('/login');
        }
      }
    );
  }
}
