import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../../api/auth.service';
import { Subscription, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { UserState } from '../../../states/user-state';
import { User } from '../../../model/user';

@Component({
  selector: 'fury-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, OnDestroy {

  isOpen: boolean;
  loading: boolean = false;
  logoutSub: Subscription = new Subscription();

  user: User;
  @Select(UserState) $user: Observable<User>;
  $userSub = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.$user.subscribe(data => {
      this.user = data;
    });
  }

  logout() {
    this.loading = true;

    this.logoutSub.unsubscribe();
    this.logoutSub = this.authService.logout().subscribe(
      (res) => {
        res.data
        this.authService.removeLocalStorage();
        this.router.navigate(['/login']);
      }, (err: HttpErrorResponse) => {
        this.hideLoading();
      }
    );
  }

  hideLoading() {
    setTimeout(() => { this.loading = false; }, 1000);
  }

  ngOnDestroy() {
    this.logoutSub.unsubscribe();
    this.$userSub.unsubscribe();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

}
