import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '../model/response';
import { Login } from '../model/login';
import { User } from '../model/user';
import { Store } from '@ngxs/store';
import { LoadUser, RemoveUser } from '../states/user-state';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  login(username: string, password: string): Observable<Response<Login>> {
    return this.http.post<Response<Login>>('auth/login', { username: username, password: password });
  }

  logout(): Observable<Response<null>> {
    return this.http.post<Response<null>>('auth/logout', null);
  }

  logoutAll(): Observable<Response<string>> {
    return this.http.post<Response<string>>('auth/logoutAll', null);
  }

  getProfile(): Observable<Response<User>> {
    return this.http.get<Response<User>>('auth/profile');
  }

  updateProfile(email: string, username: string, password: string): Observable<Response<any>> {
    return this.http.post<Response<any>>('admin/auth/updateProfile', { email, username, password });
  }

  setLocalStorage(res: Response<Login>): void {
    localStorage.setItem('token', res.data.token);
    localStorage.setItem('user', JSON.stringify(res.data.user));

    this.store.dispatch(new LoadUser(res.data.user));
  }

  removeLocalStorage(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    this.store.dispatch(new RemoveUser());
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem('user'));
  }
}
