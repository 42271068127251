import { Component, OnChanges, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { LOADERS } from './loading-layout';

@Component({
  selector: 'fury-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnChanges {

  /**
   * To set backdrop opacity(0.8)
   *
   * @memberof NgxSpinnerComponent
   */
  @Input() bdOpacity = 0.8;

  /**
   * To set backdrop color('#333')
   *
   * @memberof NgxSpinnerComponent
   */
  @Input() bdColor = '#333';

  /**
   * To set spinner size
   *
   * @memberof NgxSpinnerComponent
   */
  @Input() size = 'large';

  /**
   * To set spinner color('#fff')
   *
   * @memberof NgxSpinnerComponent
   */
  @Input() color = '#fff';

  /**
   * To set type of spinner
   *
   * @memberof NgxSpinnerComponent
   */
  @Input() type: string;
  /**
   * To set loading text(false)
   *
   * @memberof NgxSpinnerComponent
   */
  @Input() loadingText = false;

  /**
   * Class for spinner
   *
   */
  spinnerClass: string;

  /**
   * Array for spinner divs
   *
   */
  divArray: Array<number> = [];

  /**
   * Counter for div
   *
   */
  divCount = 0;

  constructor(
    
  ){}

  ngOnChanges(changes: SimpleChanges) {
    const typeChange: SimpleChange = changes.type;
    const sizeChange: SimpleChange = changes.size;

    if (typeChange) {
      if (typeof typeChange.currentValue !== 'undefined' && typeChange.currentValue !== typeChange.previousValue) {
        if (typeChange.currentValue !== '') {
          this.type = typeChange.currentValue;
          this.onInputChange();
        }
      }
    }

    if (sizeChange) {
      if (typeof sizeChange.currentValue !== 'undefined' && sizeChange.currentValue !== sizeChange.previousValue) {
        if (sizeChange.currentValue !== '') {
          this.size = sizeChange.currentValue;
          this.onInputChange();
        }
      }
    }
  }

  /**
   * To get class for spinner
   *
   */
  getClass(type = 'ball-scale-multiple', size = 'large'): string {
    this.divCount = LOADERS[type];
    this.divArray = Array(this.divCount).fill(0).map((x, i) => i);
    let sizeClass = '';
    switch (size.toLowerCase()) {
      case 'small':
        sizeClass = 'la-sm';
        break;
      case 'medium':
        sizeClass = 'la-2x';
        break;
      case 'large':
        sizeClass = 'la-3x';
        break;
      default:
        sizeClass = 'la-3x';
        break;
    }
    return 'la-' + type + ' ' + sizeClass;
  }

  /**
   * After input variables chnage event
   *
   */
  onInputChange() {
    this.spinnerClass = this.getClass(this.type, this.size);
  }

}
