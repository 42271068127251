import { Component, OnInit } from '@angular/core';
import { SidenavItem } from './core/sidenav/sidenav-item/sidenav-item.interface';
import { SidenavService } from './core/sidenav/sidenav.service';
import { Store } from '@ngxs/store';
import { AuthService } from './api/auth.service';
import { LoadUser } from './states/user-state';
import pwc from 'pretty-web-console';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    sidenavService: SidenavService,
    private authService: AuthService,
    private store: Store
  ) {
    const menu: SidenavItem[] = [];

    menu.push({
      name: 'Dashboard',
      routeOrFunction: '/',
      icon: 'dashboard',
      pathMatchExact: true
    });

    menu.push({
      name: 'Komplain',
      routeOrFunction: '/complaints',
      icon: 'list',
      pathMatchExact: true
    });

    menu.push({
      name: 'User Aktif',
      routeOrFunction: '/users',
      icon: 'supervisor_account',
      pathMatchExact: true
    });

    menu.push({
      name: 'User Tidak Aktif',
      routeOrFunction: '/inactive-users',
      icon: 'assignment_ind',
      pathMatchExact: true
    });

    menu.push({
      name: 'Links',
      routeOrFunction: '/links',
      icon: 'links',
      pathMatchExact: true
    });

    // Send all created Items to SidenavService
    menu.forEach(item => sidenavService.addItem(item));
  }

  ngOnInit() {
    this.store.dispatch(new LoadUser(this.authService.getUser()));

    pwc({ color: 'white' })
      .size(30)
      .bgdarkslategray()
      .bold()
      .info('We are hiring!\n🎨 Frontend developer');
  }
}
